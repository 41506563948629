'use strict';

angular.module('cpformplastApp.jobs')
    .factory('MachineSchedule', function(SubjobSchedule, $http, appConfig) {

    class MachineSchedule {
        constructor(jsonSchedule) {
          this.buildFromJSON(jsonSchedule);
        }

        buildFromJSON({id, date, start, end, machineId, isDefaultSchedule, isOn}) {
          this.id = id;
          this.date = new Date(date);
          this.start = start ? new Date(start) : undefined;
          this.end = end ? new Date(end) : undefined;
          this.machineId = machineId;
          this.isDefaultSchedule = isDefaultSchedule;
          this.isOn = isOn ? isOn : false;
          return this;
        }

        getJson() {
          return {
            date: this.date.toISOString(),
            start: this.start && this.isOn ? this.start.toISOString() : null,
            end: this.end && this.isOn ? this.end.toISOString() : null
          };
        }

        setStartTime(hour) {
          const currentStart = new Date(this.start);

          this.start.setHours(hour, 0, 0, 0);
          this.save()
          .catch(err => {this.start = currentStart});
        }

        setEndTime(hour) {
          const currentEnd = new Date(this.end);

          this.end.setHours(hour, 0, 0, 0);
          this.save()
          .catch(err => {this.end = currentEnd});
        }

        isPassed() {
          const startOfDay = new Date();
          return this.end ? this.end < startOfDay : this.date < startOfDay;
        }

        save() {
          if (this.isOn) {
            if (!this.start) {
              this.start = new Date(this.date);
              this.start.setHours(7,0,0,0);
            }
            if (!this.end) {
              this.end = new Date(this.date);
              this.end.setHours(16,0,0,0);
            }
          }

          const promise = this.id ? this.update() : this.create();
          return promise.then(data => this.buildFromJSON(data));
        }

        create() {
    			return $http.post(appConfig.api.url + '/api/machines/' + this.machineId + '/schedules', this.getJson()).then(function(respond){
    				return respond.data;
    			});
    		};

    		update() {
    			return $http.put(appConfig.api.url + '/api/machines/' + this.machineId + '/schedules/' + this.id, this.getJson()).then(function(respond){
    				return respond.data;
    			});
    		};
    }

    return MachineSchedule;
});
